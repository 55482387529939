import styled from 'styled-components'
import promotorArrow from '../../../images/promotor-arrow-icon.svg'

export const PromoterScoreSection = styled.section`
  padding: 5rem 0;
  background: linear-gradient(180deg, rgba(242, 242, 242, 0) 0%, #f2f2f2 100%);
`
export const PromoterScoreWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 5rem 0 12rem 0;
`

export const Heading = styled.h2`
  font-family: 'Barlow';
  font-size: 5.71rem;
  font-weight: 800;
  line-height: 6.85rem;
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  text-align: center;
  padding: 2rem 0 5rem 0;

  @media (max-width: 425px) {
    font-size: 4.28rem;
    line-height: 5.14rem;
    padding: 0;
  }

  @media (max-width: 1024px) {
    padding: 1rem 0;
  }
`

export const Divider = styled.div`
  width: 1px;
  height: 600px;
  background: linear-gradient(
    180deg,
    rgba(234, 234, 234, 0) 0%,
    #1d9ed9 34.38%,
    #eaeaea 66.68%,
    rgba(234, 234, 234, 0) 100%
  );
`
export const Column = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  justify-content: center;
`
export const Tab = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  span {
    display: flex;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100px;
    background-color: #fff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    transition-property: transform;
    transition-duration: 400ms;
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);

    &::before {
      content: '';
      display: inline-flex;
      width: 19px;
      height: 16px;
      background-image: url(${promotorArrow});
      background-repeat: no-repeat;
      margin-left: 1.5rem;
    }
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 480px;

  &.active {
    animation: fadeIn 0.5s ease-in-out;
  }

  img {
    align-self: center;
    width: 150px;
  }
`
export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: ${(props) => (props.score ? '14.2rem' : '2.85rem')};
  line-height: ${(props) => (props.score ? '17rem' : '3.57rem')};
  font-weight: 800;
  text-align: center;

  strong {
    font-size: 4rem;
  }
`

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-size: 1.14rem;
  line-height: inherit;
  margin-top: 2.14rem;
  text-align: center;
`

export const List = styled.ul`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 1.71rem;
  font-weight: 600;
  line-height: 6.2rem;
`
export const ListItem = styled.li`
  position: relative;
  padding: 0.5rem 1.5rem;
  margin-left: 6rem;
  opacity: 0.4;
  transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
    transform: translateX(-15%);
  }
`
