import React from 'react'

const ArrowRightGreen = ({ className, width, height }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-7.2126e-08 6C-3.22919e-08 6.41421 0.309957 6.75 0.692308 6.75L17.3077 6.75C17.69 6.75 18 6.41421 18 6C18 5.58579 17.69 5.25 17.3077 5.25L0.692308 5.25C0.309957 5.25 -1.1196e-07 5.58579 -7.2126e-08 6Z"
        fill="#A9CF3A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9723 11.7803C12.2427 12.0732 12.681 12.0732 12.9514 11.7803L17.7975 6.53033C18.0679 6.23744 18.0679 5.76256 17.7975 5.46967L12.9514 0.21967C12.681 -0.0732231 12.2427 -0.0732231 11.9723 0.21967C11.7019 0.512563 11.7019 0.987437 11.9723 1.28033L16.3289 6L11.9723 10.7197C11.7019 11.0126 11.7019 11.4874 11.9723 11.7803Z"
        fill="#A9CF3A"
      />
    </svg>
  )
}

const defaultProps = {
  width: 18,
  height: 12
}

ArrowRightGreen.defaultProps = defaultProps
export default ArrowRightGreen
