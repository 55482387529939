import styled from 'styled-components'
import { Link } from 'gatsby'

export const PropertySection = styled.section`
  padding: 2rem 0;
`
export const PropertyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 4rem;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`
export const PropertyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 1.78rem;
  flex-basis: 0;

  @media (max-width: 425px) {
    margin: 4rem 0;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    flex-basis: 40%;
  }

  @media (max-width: 615px) {
    flex-basis: 100%;
  }
`

export const ImageContainer = styled.figure`
  display: flex;
  justify-content: center;
  position: relative;
  width: 230px;
  height: 175px;

  img {
    position: absolute;
  }

  img:first-of-type {
    z-index: 1;
    transition: opacity 0.2s ease-in-out;
  }
`

export const Heading = styled.h2`
  font-family: 'Barlow';
  font-size: 5.71rem;
  font-weight: 800;
  line-height: 6.85rem;
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  text-align: center;
  padding: 2rem 0 5rem 0;

  @media (max-width: 425px) {
    font-size: 3.28rem;
    line-height: 4.14rem;
    padding: 0;
  }

  @media (max-width: 1024px) {
    padding: 1rem 0;
  }
`

export const Title = styled.h3`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2.14rem;
  margin-bottom: 1.85rem;
  text-align: center;

  @media (max-width: 425px) {
    font-size: 2rem;
    line-height: 2.3rem;
    margin-bottom: 1rem;
  }
`

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-size: 1.14rem;
  line-height: 1.7rem;
  text-align: center;
  padding: 0 0.5rem;
  margin-bottom: auto;
  opacity: 0.8;

  @media (max-width: 425px) {
    width: 75%;
  }
`

export const StyledLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.vividBlue};
  font-weight: 500;
  text-decoration: none;

  position: relative;

  &:hover {
    img:first-of-type {
      opacity: 0;
    }
    span {
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 6px;
        background-color: ${({ theme }) => theme.colors.vividBlue};
        opacity: 0.3;
        bottom: 6px;
        left: 0;
      }
    }
  }

  span {
    display: inline-block;
    position: relative;
    margin-top: 1rem;
    text-transform: uppercase;
  }
`
