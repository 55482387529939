import styled, { css } from 'styled-components'
import headerShape from '../../../images/header-shape.svg'

const gradientText = css`
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.veryDarkBlue},
    ${({ theme }) => theme.colors.darkBlue}
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`
export const HeroWrapper = styled.div`
  height: 700px;
  background-color: white;
  background-image: ${(props) => (props.transparent ? 'none' : `url(${headerShape})`)};
  background-size: cover;
  background-position: bottom;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 425px) {
    height: 600px;
  }
`

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 60%;

  @media screen and (max-width: 425px) {
    width: 75%;
  }
`

export const Title = styled.h1`
  ${gradientText}
  font-family: 'Barlow', sans-serif;
  font-size: 4.28rem;
  font-weight: 900;
  line-height: 5rem;
  max-width: 1000px;
  margin: 0 auto;

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 3.85rem;
    line-height: 4rem;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    font-size: 2.2rem;
    line-height: 2.4rem;
  }
`
export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  margin-top: 2rem;
  opacity: 0.6;

  @media (max-width: 425px) {
    font-size: 1rem;
    line-height: 1.71rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
`
export const ImageBox = styled.div`
  z-index: 1;
  position: absolute;
  top: 55%;
  width: 800px;
  left: 50%;
  transform: scale(1) translateX(-50%);
  background-image: url(${({ img }) => img});

  @media (max-width: 1024px) {
    width: 600px;
  }

  @media (max-width: 768px) {
    width: 500px;
  }

  @media (max-width: 500px) {
    width: 350px;
  }

  @media (max-width: 425px) {
    top: 70%;
    width: 75%;
  }
`
