import React, { useEffect, useState } from 'react'
import { useInterval } from '../../Slider/useInterval'
import {
  PromoterScoreSection,
  PromoterScoreWrapper,
  Heading,
  Divider,
  Column,
  Tab,
  TabContent,
  Title,
  Text,
  List,
  ListItem
} from './styles'

import Container from '../../UI/Container'
import Capterra from 'assets/images/capterra-badge.png'

const PromoterScore = ({ data }) => {
  const { customer_numbers_title, tab_section: content } = data

  const [currentTab, setCurrentTab] = useState(0)
  const [autoPlay, setAutoPaly] = useState(true)

  const handleClick = (currentTab) => {
    setCurrentTab(currentTab)
    setAutoPaly(false)
  }

  useInterval(
    () => {
      setCurrentTab((currentTab + 1) % 4)
    },
    autoPlay ? 4000 : null
  )

  return (
    <PromoterScoreSection>
      <Container>
        <Heading>{customer_numbers_title}</Heading>
        <PromoterScoreWrapper>
          <Column>
            {currentTab === 0 && (
              <TabContent className={`${currentTab === 0 ? 'active' : ''}`}>
                <>
                  <Title dangerouslySetInnerHTML={{ __html: content[currentTab].title }} />
                  <Text dangerouslySetInnerHTML={{ __html: content[currentTab].content }} />
                </>
              </TabContent>
            )}
            {currentTab === 1 && (
              <TabContent className={`${currentTab === 1 ? 'active' : ''}`}>
                <>
                  {content[currentTab].title === 'capterra' ? (
                    <img src={Capterra} alt="Captera" />
                  ) : (
                    <Title dangerouslySetInnerHTML={{ __html: content[currentTab].title }} />
                  )}

                  <Text dangerouslySetInnerHTML={{ __html: content[currentTab].content }} />
                </>
              </TabContent>
            )}
            {currentTab === 2 && (
              <TabContent className={`${currentTab === 2 ? 'active' : ''}`}>
                <>
                  <Title dangerouslySetInnerHTML={{ __html: content[currentTab].title }} />
                  <Text dangerouslySetInnerHTML={{ __html: content[currentTab].content }} />
                </>
              </TabContent>
            )}
            {currentTab === 3 && (
              <TabContent className={`${currentTab === 3 ? 'active' : ''}`}>
                <>
                  <Title dangerouslySetInnerHTML={{ __html: content[currentTab].title }} />
                  <Text dangerouslySetInnerHTML={{ __html: content[currentTab].content }} />
                </>
              </TabContent>
            )}
          </Column>
          <Divider />
          <Column>
            <Tab>
              <span
                style={{
                  transform: `translate(-5%, calc(100% * ${currentTab}))`
                }}
              />
              <List>
                {content.map((item, i) => (
                  <ListItem
                    className={`${currentTab === i ? 'active' : ''}`}
                    key={`${item.tab_text}-${i}`}
                    onClick={() => handleClick(i)}
                  >
                    {item.tab_text}
                  </ListItem>
                ))}
              </List>
            </Tab>
          </Column>
        </PromoterScoreWrapper>
      </Container>
    </PromoterScoreSection>
  )
}

export default PromoterScore
