import React, { createContext, useContext, useState, useEffect } from 'react'

export const WindowDimensions = createContext(null)

const WindowDimensionsProvider = ({ children }) => {
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0
  })
  useEffect(() => {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth
    })
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  return <WindowDimensions.Provider value={dimensions}>{children}</WindowDimensions.Provider>
}

export default WindowDimensionsProvider

export const useWindowDimensions = () => {
  return useContext(WindowDimensions)
}
