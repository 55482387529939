import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Transition } from 'react-transition-group'
import { useCookies } from 'react-cookie'

import SEO from 'components/SEO'
import Header from 'components/Header'
import OtaChannels from 'components/Home-screen/OTA-Channels'
import Property from 'components/Home-screen/Property'
import Join from 'components/Home-screen/Join'
import Feature from 'components/Home-screen/Feature'
import PromoterScore from 'components/Home-screen/Promoter-score'
import FeaturedCustomer from 'components/Home-screen/Featured-customer'
import CallToAction from 'components/CTA'
import BlogStories from 'components/Home-screen/Blog-stories'
import Footer from 'components/Footer'

import RentlioLine from 'components/Rentlio-line/rentlio-line'
import Layout from '../components/Layout'
import Hero from 'components/Home-screen/Hero'
import PromoterScoreMobile from 'components/Home-screen/Promoter-score-mobile'
import Responsive from '../components/Responsive'
import WindowSizeProvider from 'components/WindowSizeProvider'
import CookieBar from '../components/UI/Cookie-bar'

const MainContent = styled.main`
  padding-top: 40rem;
  background-color: #ffffff;

  @media (max-width: 425px) {
    padding-top: 1rem;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    padding-top: 30rem;
  }

  @media (min-width: 426px) and (max-width: 768px) {
    padding-top: 15rem;
  }
`
const animationTiming = {
  enter: 600,
  exit: 600
}
const IndexPage = ({ data, pageContext, location }) => {
  const [cookies, setCookie] = useCookies()
  const [showCookieBar, setShowCookieBar] = useState(false)

  const handleSetCookie = () => {
    const current = new Date()
    const nextYear = new Date()

    setCookie('cookieStatement', true, {
      expires: new Date(nextYear.setFullYear(current.getFullYear() + 1)),
      path: '/'
    })
    setShowCookieBar(false)
  }

  const {
    home: {
      title,
      yoast_meta: { yoast_wpseo_title, yoast_wpseo_metadesc },
      header,
      ota,
      property,
      join,
      feature,
      customerNumbers,
      translations,
      lang,
      ctaAction: { cta_home }
    },
    blogStories: { edges: blogStories },
    featuredCustomers: { edges: featuredCustomers },
    authorities: { edges: authorities }
  } = data

  const seo = {
    title: yoast_wpseo_title || title,
    description: yoast_wpseo_metadesc,
    lang: pageContext.locale,
    pathname: location.pathname
  }

  useEffect(() => {
    if (!cookies.cookieStatement) {
      setShowCookieBar(true)
    }
  }, [])

  return (
    <Layout locale={pageContext.locale}>
      <SEO
        title={seo.title}
        description={seo.description}
        pathname={seo.pathname}
        lang={seo.lang}
        location={location}
      />
      <Header
        mode="dark"
        locale={pageContext.locale}
        opacity={0.3}
        location={location}
        translations={translations}
        language={lang}
      />
      <Hero title={header.title} subtitle={header.subtitle}></Hero>
      <RentlioLine />
      <MainContent>
        <OtaChannels ota={ota} />
        <Property property={property} location={location} />
        <Join join={join} location={location} />
        <Feature feature={feature} />
        <WindowSizeProvider>
          <Responsive
            breakPoint={1024}
            renderDesktop={() => <PromoterScore data={customerNumbers} />}
            renderMobile={() => <PromoterScoreMobile data={customerNumbers} />}
          />
        </WindowSizeProvider>
        <FeaturedCustomer featuredCustomers={featuredCustomers} locale={pageContext.locale} location={location} />
        <CallToAction data={cta_home} locale={pageContext.locale} location={location} />
        <BlogStories blogStories={blogStories} locale={pageContext.locale} location={location} />
      </MainContent>
      <Footer locale={pageContext.locale} location={location} />
      {/* {showCookieBar && (
        <Transition in={!cookies.cookieStatement} mountOnEnter unmountOnExit timeout={animationTiming}>
          {(state) => <CookieBar locale={pageContext.locale} handleSetCookie={handleSetCookie} state={state} />}
        </Transition>
      )} */}
    </Layout>
  )
}

export default IndexPage

IndexPage.propTypes = {
  data: PropTypes.shape({
    home: PropTypes.shape({
      header: PropTypes.object.isRequired,
      ota: PropTypes.object.isRequired,
      property: PropTypes.object.isRequired,
      join: PropTypes.object.isRequired,
      feature: PropTypes.object.isRequired
    })
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
}

export const query = graphql`
  fragment featureImg on wordpress__wp_media {
    localFile {
      childImageSharp {
        fluid(maxWidth: 500, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }

  query IndexPage($id: Int, $locale: String!) {
    home: wordpressPage(wordpress_id: { eq: $id }, lang: { eq: $locale }) {
      title
      translations {
        en
        hr
      }
      lang
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
      }

      header: acf {
        title
        subtitle
      }

      ota: acf {
        ota_text
      }

      property: acf {
        property_heading
        hotel {
          title
          text
          url
        }
        hostel {
          title
          text
          url
        }
        vacation_rentals {
          title
          text
          url
        }
        property_managers {
          title
          text
          url
        }
      }

      join: acf {
        join_heading
        join_text
        join_button_text
        join_button_url
      }

      feature: acf {
        calendar {
          title
          subtitle
          text
          image {
            ...featureImg
          }
        }
        mobile_apps {
          title
          subtitle
          text
          image {
            ...featureImg
          }
        }
        channel_manager {
          title
          subtitle
          text
          image {
            ...featureImg
          }
        }
        booking_engine {
          title
          subtitle
          text
          image {
            ...featureImg
          }
        }
        featured_button {
          text
          url
        }
      }
      customerNumbers: acf {
        customer_numbers_title
        tab_section {
          tab_text
          title
          content
        }
      }
      ctaAction: acf {
        cta_home {
          title
          description
        }
      }
    }
    authorities: allWordpressAcfAuthority {
      edges {
        node {
          wordpress_id
          acf {
            banner_title
            language
            slug
          }
        }
      }
    }
    blogStories: allWordpressPost(
      filter: { tags: { elemMatch: { slug: { eq: "blog-stories" } } }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          title
          slug
          content
          acf {
            author_name
            author_image {
              localFile {
                childImageSharp {
                  fluid(quality: 75, maxWidth: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 75, maxWidth: 340) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    featuredCustomers: allWordpressPost(
      filter: { tags: { elemMatch: { slug: { eq: "featured-customer" } } }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          title
          slug
          featured_media {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 580, maxHeight: 520) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
