import React from 'react'
import PromoterSlider from 'react-slick'

import { PromoterScoreMobileSection, PromoterSliderWrapper, Heading, Title, Content, PromoterSlide } from './styles'
import Container from '../../UI/Container'
import Capterra from 'assets/images/capterra-badge.png'

const PromoterScoreMobile = ({ data }) => {
  const { customer_numbers_title, tab_section: content } = data

  const settings = {
    dots: true,
    arrows: false,
    fade: true,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000
  }
  return (
    <PromoterScoreMobileSection>
      <Container>
        <Heading>{customer_numbers_title}</Heading>
        <PromoterSliderWrapper>
          <PromoterSlider {...settings}>
            {content.map((item, i) => {
              return (
                <PromoterSlide key={i}>
                  {item.title === 'capterra' ? (
                    <figure>
                      <img src={Capterra} alt="Captera" />
                    </figure>
                  ) : (
                    <Title dangerouslySetInnerHTML={{ __html: item.title }} />
                  )}
                  <Content dangerouslySetInnerHTML={{ __html: item.content }} />
                </PromoterSlide>
              )
            })}
          </PromoterSlider>
        </PromoterSliderWrapper>
      </Container>
    </PromoterScoreMobileSection>
  )
}

export default PromoterScoreMobile
