import React from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import { handleTrackedRentlioWebappClick } from 'helper/url'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

import {
  BlogStoriesSection,
  BlogStoriesWrapper,
  ReadMoreWrapper,
  StyledLink,
  Cards,
  Card,
  CardImageWrapper,
  CardContent,
  CardTitle,
  CardFooter,
  Author,
  Time
} from './styles'

import Container from '../../UI/Container'
import ArrowRightGreen from './Icons/arrow-right-green'
import { LocaleConsumer } from '../../Layout'
import { timeToRead } from '../../../helper'

const BlogStories = ({ blogStories, locale, location }) => {
  const lang = locale === 'en' ? `/${locale}/` : '/'
  const mixpanel = useMixpanel()

  const RenderCard = ({ title, content, acf, image, slug }) => {
    const {
      author_name,
      author_image: {
        localFile: {
          childImageSharp: { fluid }
        }
      }
    } = acf
    const {
      localFile: {
        childImageSharp: { fluid: featured }
      }
    } = image

    return (
      <Card>
        <Link to={`${lang}blog/${slug}${location.search}`}>
          <CardImageWrapper>
            <Img className="card-image" fluid={featured} />
          </CardImageWrapper>
          <CardContent>
            <CardTitle dangerouslySetInnerHTML={{ __html: title }} />
          </CardContent>
          <CardFooter>
            <Author>
              <figure>
                <Img objectFit="cover" fluid={fluid} />
              </figure>
              <span>{author_name}</span>
            </Author>
            <Time>
              <span>{timeToRead(content)} read</span>
            </Time>
          </CardFooter>
        </Link>
      </Card>
    )
  }
  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <BlogStoriesSection>
          <Container>
            <BlogStoriesWrapper>
              <ReadMoreWrapper>
                <span>{i18n.exploreCase}</span>
                <div>
                  <ArrowRightGreen className="right-arrow" />
                  <StyledLink
                    to={`${lang}blog${location.search}`}
                    onClick={(e) =>
                      handleTrackedRentlioWebappClick(
                        e,
                        '',
                        '',
                        mixpanel,
                        'blog_section_clicked',
                        'read_more_link',
                        false,
                        true
                      )
                    }
                  >
                    {i18n.findOutMore}
                  </StyledLink>
                </div>
              </ReadMoreWrapper>
              <Cards>
                {blogStories.slice(0, 3).map((story) => {
                  return (
                    <RenderCard
                      key={story.node.id}
                      title={story.node.title}
                      content={story.node.content}
                      acf={story.node.acf}
                      image={story.node.featured_media}
                      slug={story.node.slug}
                    />
                  )
                })}
              </Cards>
            </BlogStoriesWrapper>
          </Container>
        </BlogStoriesSection>
      )}
    </LocaleConsumer>
  )
}

BlogStories.defaultProps = {
  location: {
    search: ''
  }
}

export default BlogStories
