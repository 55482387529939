import styled from 'styled-components'

export const PromoterScoreMobileSection = styled.section`
  padding: 2rem 0;
  background: linear-gradient(180deg, rgba(242, 242, 242, 0) 0%, #f2f2f2 100%);
`

export const PromoterSliderWrapper = styled.div`
  padding: 1rem 0 8rem 0;

  .slick-dots {
    bottom: 0;
  }

  .slick-dots li button:before {
    font-size: 12px !important;
  }

  .slick-dots li.slick-active button:before {
    color: ${({ theme }) => theme.colors.vividBlue} !important;
    opacity: 1 !important;
  }

  .slick-dots li button {
    color: ${({ theme }) => theme.colors.veryLightGray};
    &::before {
      color: ${({ theme }) => theme.colors.grey};
    }
  }
  .slick-dots li button:focus:before {
    opacity: 0.25;
  }
  .slick-dots li.slick-active button:focus:before,
  .slick-dots li button:hover:before {
    opacity: 1;
  }
`

export const Heading = styled.h2`
  font-family: 'Barlow';
  font-size: 5.71rem;
  font-weight: 800;
  line-height: 6.85rem;
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  text-align: center;
  padding: 2rem 0 5rem 0;

  @media (max-width: 425px) {
    font-size: 3.28rem;
    line-height: 4.14rem;
    padding: 0;
  }

  @media (max-width: 1024px) {
    padding: 1rem 0;
  }
`

export const PromoterSlide = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 3.5rem 0;
  outline: none;

  figure {
    width: 150px;
    margin: 0 auto;
  }
`

export const Title = styled.h4`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 2.85rem;
  font-weight: 800;
  line-height: 3.57rem;
  margin-bottom: 3.5rem;
  text-align: center;

  @media (max-width: 425px) {
    font-size: 2rem;
    line-height: 2.4rem;
    padding: 0;
  }
`

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-size: 1.14rem;
  line-height: 1.71rem;
  opacity: 0.8;
  text-align: center;
  width: 75%;
  margin: 0 auto;
`
