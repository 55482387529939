import React from 'react'
import PropTypes from 'prop-types'

import { OtaSection, OtaWrapper, TextBox } from './styles'
import Container from '../../UI/Container'

import Booking from '../../../images/booking.svg'
import Airbnb from '../../../images/airbnb.svg'
import Expedia from '../../../images/expedia.svg'
import HotelBeds from '../../../images/hotel-beds.svg'
import HostelWorld from '../../../images/hostel-world.svg'

const OtaChannels = ({ ota }) => {
  return (
    <OtaSection>
      <Container>
        <OtaWrapper>
          <figure>
            <img src={Booking} alt="Booking" />
          </figure>
          <figure>
            <img src={Airbnb} alt="Airbnb" />
          </figure>
          <figure>
            <img src={Expedia} alt="Expedia" />
          </figure>
          <figure>
            <img src={HotelBeds} alt="Hotel Beds" />
          </figure>
          <figure>
            <img src={HostelWorld} alt="Hostel World" />
          </figure>
        </OtaWrapper>
        <TextBox dangerouslySetInnerHTML={{ __html: ota.ota_text }} />
      </Container>
    </OtaSection>
  )
}

export default OtaChannels

OtaChannels.propTypes = {
  ota: PropTypes.object
}
