import React from 'react'
import PropTypes from 'prop-types'

import { JoinSection, JoinWrapper, Heading, Text } from './styles'
import Container from '../../UI/Container'
import Button from '../../Button'

import { handleTrackedRentlioWebappClick } from 'helper/url'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

const Join = ({ join, location }) => {
  const { join_heading, join_text, join_button_text, join_button_url } = join
  const mixpanel = useMixpanel()
  return (
    <JoinSection>
      <Container>
        <JoinWrapper>
          <Heading dangerouslySetInnerHTML={{ __html: join_heading }} />
          <Text>{join_text}</Text>
          <Button
            type="large"
            href={`${join_button_url}${location.search}`}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => handleTrackedRentlioWebappClick(e, join_button_url, location.search, mixpanel, 'try_free_button_clicked', 'page_middle', true, true, true)}
          >
            {join_button_text}
          </Button>
        </JoinWrapper>
      </Container>
    </JoinSection>
  )
}

Join.propTypes = {
  join: PropTypes.object,
  location: PropTypes.object
}

Join.defaultProps = {
  location: {
    search: ''
  }
}

export default Join
