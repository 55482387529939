import styled from 'styled-components'

export const OtaSection = styled.section`
  padding: 5rem 0;

  @media screen and (max-width: 425px) {
    padding: 5rem 0 4rem 0;
  }
`
export const OtaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`

export const TextBox = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.42rem;
  line-height: 2.57rem;
  text-align: center;
  margin-top: 11rem;
  opacity: 0.8;
  width: 95%;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 425px) {
    font-size: 1.14rem;
    line-height: 1.71rem;
    margin-top: 7rem;
  }
`
