import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  transform: translateY(-40%);

  @media (max-width: 768px) {
    display: none;
  }
`

const RentlioLine = () => (
  <Wrapper>
    <svg width="100%" height="257" viewBox="0 0 5439 257" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="url(#paint0_linear)"
        strokeWidth="20"
        d="M5438 98.0003C5201.5 80.1976 5083.5 52.501 4769.5 166.804C4556.72 244.259 4218.5 300.5 4045 196.959C3744.33 17.5251 3557.25 115.53 3436.5 166.804C3245.5 247.908 3112 266.594 3034 196.959C2786.5 -23.9997 2632.5 282.047 2453 149.024C2273.5 16.0004 2191 -51.9999 1998 81.4588C1805 214.917 1703.5 285.5 1429.5 149.024C1304.5 86.7625 1105 -100.499 695.5 81.4588C382 196.959 186 149.024 2 81.4588"
      />
      {/* <animate
          repeatCount="indefinite"
          fill="freeze"
          attributeName="d"
          dur="20s"
          values="M5438 98.0003C5201.5 80.1976 5083.5 52.501 4769.5 166.804C4556.72 244.259 4218.5 300.5 4045 196.959C3744.33 17.5251 3557.25 115.53 3436.5 166.804C3245.5 247.908 3112 266.594 3034 196.959C2786.5 -23.9997 2632.5 282.047 2453 149.024C2273.5 16.0004 2191 -51.9999 1998 81.4588C1805 214.917 1703.5 285.5 1429.5 149.024C1304.5 86.7625 1105 -100.499 695.5 81.4588C382 196.959 186 149.024 2 81.4588;"
        /> */}
      {/* values="M5438 98.0003C5201.5 80.1976 5083.5 52.501 4769.5 166.804C4556.72 244.259 4218.5 300.5 4045 196.959C3744.33 17.5251 3557.25 115.53 3436.5 166.804C3245.5 247.908 3112 266.594 3034 196.959C2786.5 -23.9997 2632.5 282.047 2453 149.024C2273.5 16.0004 2191 -51.9999 1998 81.4588C1805 214.917 1703.5 285.5 1429.5 149.024C1304.5 86.7625 1105 -100.499 695.5 81.4588C382 196.959 186 149.024 2 81.4588;
          M5437.5 127.344C5231.5 242.82 4898.5 195.913 4770.5 127.344C4570.9 20.4201 4196.11 84.4995 4046 157.499C3856 249.899 3553.5 188.61 3437.5 127.344C3221 12.9994 3244.88 161.73 3035 157.499C2913.15 155.043 2767.5 35.629 2454 109.564C2140.5 183.5 2131.5 71.8668 1999 41.9994C1866.5 12.132 1708.5 14 1430.5 109.564C1296.33 154.209 961.7 203.199 696.5 41.9994C589.5 -23.0393 298.8 -18.5006 2 157.499;
          M5438 98.0003C5201.5 80.1976 5083.5 52.501 4769.5 166.804C4556.72 244.259 4218.5 300.5 4045 196.959C3744.33 17.5251 3557.25 115.53 3436.5 166.804C3245.5 247.908 3112 266.594 3034 196.959C2786.5 -23.9997 2632.5 282.047 2453 149.024C2273.5 16.0004 2191 -51.9999 1998 81.4588C1805 214.917 1703.5 285.5 1429.5 149.024C1304.5 86.7625 1105 -100.499 695.5 81.4588C382 196.959 186 149.024 2 81.4588"
         */}
      {/* </path> */}
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="5438"
          y1="128.685"
          x2="3.14157"
          y2="128.685"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A9CF3A" />
          <stop offset="0.0515359" stopColor="#A7CF40" />
          <stop offset="0.1291" stopColor="#A2CE52" />
          <stop offset="0.2231" stopColor="#99CD6F" />
          <stop offset="0.3298" stopColor="#8BCB92" />
          <stop offset="0.4466" stopColor="#78CABB" />
          <stop offset="0.5633" stopColor="#59C9E7" />
          <stop offset="1" stopColor="#1D9ED9" />
        </linearGradient>
      </defs>
    </svg>
  </Wrapper>
)

export default RentlioLine
