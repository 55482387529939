import styled, { css } from 'styled-components'
import Icon from 'assets/images/cookie-icon.jpg'

export const CookieWrapper = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  padding: 2.14rem;
  bottom: 20px;
  left: 50%;
  width: 100%;
  max-width: 1000px;
  z-index: 999;
  transform: translateX(-50%);
  ${({ state }) =>
    state === 'entered'
      ? css`
          animation: slide-in-bottom 0.6s cubic-bezier(0.075, 0.82, 0.165, 1) both;
        `
      : state === 'exiting'
      ? css`
          animation: fade-out 0.6s cubic-bezier(0.075, 0.82, 0.165, 1) both;
        `
      : null}

  @keyframes slide-in-bottom {
    0% {
      transform: translate(-50%, 1000px);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, 0);
      opacity: 1;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`
export const CookieContent = styled.div`
  display: flex;
  align-items: center;
  padding: 2.14rem;
  background-color: white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border: 1px solid #eaeaea;
  border-radius: 10px;

  p {
    color: ${({ theme }) => theme.colors.darkGrey};
    font-size: 1rem;
    line-height: 1.71rem;
    max-width: 750px;

    > a {
      color: ${({ theme }) => theme.colors.veryDarkBlue};
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;

    p {
      text-align: center;
    }
  }

  @media screen and (max-width: 425px) {
    padding: 1rem;
    flex-direction: row;

    p {
      text-align: left;
    }

    p > span {
      display: none;
    }

    p a {
      font-size: 0.9rem;
      margin-left: 1rem;
      display: block;
      margin-right: 1rem;
      line-height: 1.3;
    }
  }
`
export const Text = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 425px) {
    flex-direction: row;
  }
`
export const CookieIcon = styled.i`
  position: relative;
  width: 50px;
  height: 50px;
  margin-right: 2.14rem;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${Icon});
  }

  @media only screen and (max-width: 768px) {
    margin-right: 0;
  }

  @media screen and (max-width: 425px) {
    width: 35px;
    height: 35px;
  }
`

export const CloseButton = styled.button`
  display: inline-block;
  appearance: none;
  border: 1px solid ${({ theme }) => theme.colors.veryLightGray};
  min-width: 120px;
  position: relative;
  color: #2f2f2f;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 8px;
  margin: 0 2rem;
  padding: 1rem;
  transition: all 0.2s ease-in-out;
  opacity: 0.7;
  background-color: ${({ theme }) => theme.colors.lightestGrey};

  cursor: pointer;

  &:hover {
    transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
  }
  @media only screen and (max-width: 768px) {
    margin-top: 1rem;
  }

  @media screen and (max-width: 425px) {
    margin: 0;
  }
`
