import styled from 'styled-components'

export const FeatureSection = styled.section`
  padding: 5rem 0;

  @media (max-width: 425px) {
    padding: 10rem 0 1rem 0;
  }
`
export const FeatureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`
export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 425px) {
    flex-basis: 100%;
  }
`

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10rem;

  @media only screen and (max-width: 425px) {
    flex-basis: 100%;
    padding-top: 0;
  }
`
export const FeatureBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8rem;
  padding: 0 2.5rem;
  max-width: 480px;
  opacity: 0;

  img {
    max-width: 270px;

    @media (max-width: 425px) {
      width: 100%;
    }
  }

  @media (max-width: 425px) {
    align-items: center;
    margin-bottom: 7rem;
    width: 90%;
    padding: 0 1rem;
  }

  @keyframes fadeInFromBottom {
    from {
      transform: translateY(0);
      opacity: 0;
    }

    to {
      transform: translateY(-50px);
      opacity: 1;
    }
  }
`
export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 3.28rem;
  font-weight: 800;
  line-height: 3.57rem;
  margin: 2rem 0 2.14rem 0;

  @media (max-width: 425px) {
    text-align: left;
    font-size: 2rem;
    line-height: 2.57rem;
  }
`
export const Text = styled.div`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-size: 1.14rem;
  line-height: inherit;
  text-align: left;
  opacity: 0.8;

  a {
    color: ${({ theme }) => theme.colors.vividBlue};

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 425px) {
    text-align: left;
  }
`
