import React, { useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { HeroWrapper, TextBox, Title, Subtitle, ImageBox } from './styles'

const Hero = ({ title, subtitle }) => {
  const {
    image: {
      childImageSharp: { fluid }
    }
  } = useStaticQuery(
    graphql`
      query {
        image: file(relativePath: { eq: "images/homepage-monitor.png" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const headerImageBox = useRef()

  const scaleOnScroll = () => {
    let progress = Math.min(1 + window.scrollY / (window.innerHeight + headerImageBox.current.clientHeight), 1.15)
    headerImageBox.current.style.transform = `scale(${progress}) translateX(-${(progress + 49) / progress}%`
  }

  useEffect(() => {
    window.addEventListener('scroll', scaleOnScroll)
    return () => {
      window.removeEventListener('scroll', scaleOnScroll)
    }
  }, [])

  return (
    <HeroWrapper>
      <TextBox>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
      </TextBox>

      <ImageBox ref={headerImageBox}>
        <Img fluid={fluid} placeholderStyle={{ visibility: 'hidden' }} />
      </ImageBox>
    </HeroWrapper>
  )
}

export default Hero
