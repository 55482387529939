import styled from 'styled-components'

export const FeaturedCustomerSection = styled.section`
  padding: 5rem 0 0 0;

  @media (max-width: 1024px) {
    margin-bottom: -10rem;
  }

  @media (max-width: 425px) {
    padding: 5rem 0 1rem 0;
  }
`
export const FeaturedCustomerWrapper = styled.div`
  padding-top: 1.7rem;
  position: relative;

  .slick-slider {
    transform: translateY(-36%);

    @media (max-width: 1024px) {
      transform: translateY(-25%);
    }
  }

  ul.slick-dots {
    bottom: -100px;
    @media (max-width: 1024px) {
      bottom: -5px;
    }
  }

  .slick-dots li button:before {
    font-size: 12px !important;
  }

  .slick-dots li.slick-active button:before {
    color: ${({ theme }) => theme.colors.vividBlue} !important;
    opacity: 1 !important;
  }

  .slick-dots li button {
    color: ${({ theme }) => theme.colors.veryLightGray};
    &::before {
      color: ${({ theme }) => theme.colors.grey};
    }
  }
  .slick-dots li button:focus:before {
    opacity: 0.25;
  }
  .slick-dots li.slick-active button:focus:before,
  .slick-dots li button:hover:before {
    opacity: 1;
  }
`
export const SlideStatus = styled.div`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.colors.darkGrey};
  line-height: 1.7rem;
  display: inline-flex;

  & span:first-child {
    font-size: 1.14rem;
    text-transform: uppercase;
    margin-right: 1rem;
  }

  & span:nth-of-type(2) {
    &::after {
      content: ' ';
      display: inline-flex;
      vertical-align: middle;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.veryLightGray};
      width: 100px;
      margin: 0 1rem;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`
export const SlideWrapper = styled.div`
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 3.5rem 0;
  outline: none;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`
export const SlideTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 528px;
  height: 500px;

  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;

    a {
      width: 100%;
    }
  }
`
export const SlideTitle = styled.h3`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 3.57rem;
  font-weight: 800;
  line-height: 4.2rem;
  padding-top: 13rem;
  margin-bottom: auto;

  @media (max-width: 1024px) {
    margin-bottom: 3rem;
    padding-top: 3rem;
    font-size: 1.7rem;
    line-height: 2.2rem;
  }
`
export const ImageWrapper = styled.figure`
  width: 100%;
  height: 100%;
  max-width: 580px;
  max-height: 520px;
  box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin: 0 auto;
  overflow: hidden;

  @media (max-width: 1024px) {
    box-shadow: none;
  }
`
