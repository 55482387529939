import styled from 'styled-components'

export const JoinSection = styled.section`
  text-align: center;
  padding: 5rem 0;
  margin-bottom: 10rem;
  background: linear-gradient(180deg, rgba(247, 247, 247, 0) 0%, #f7f7f7 100%);

  @supports (clip-path: polygon(0 0)) or (-webkit-clip-path: polygon(0 0)) {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0 100%);
  }

  @media (max-width: 425px) {
    padding-top: 1rem;
    margin: 0;

    clip-path: polygon(0 0, 100% 0, 100% 92%, 0 100%);
  }
`

export const JoinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0 15rem 0;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    right: -21px;
    bottom: -17px;
    width: 65%;
    height: 50%;
    background: radial-gradient(
        49.55% 46.82% at 50.45% 46.82%,
        rgba(169, 207, 58, 0.26) 0%,
        rgba(255, 255, 255, 0) 100%
      ),
      radial-gradient(45.91% 43.37% at 49.63% 99.83%, rgba(29, 158, 217, 0.47) 0%, rgba(29, 158, 217, 0) 100%);
    opacity: 0.4;
    z-index: -1;

    @media (max-width: 425px) {
      right: 0;
      bottom: 0;
      width: 100%;
      transform: translateY(25%);
    }
  }

  @media (max-width: 425px) {
    padding-top: 1rem;
    padding-bottom: 8rem;
  }
`
export const Heading = styled.h2`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 3.57rem;
  font-weight: 800;
  line-height: 4.28rem;
  padding-bottom: 40px;
  width: 90%;

  @media (max-width: 425px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`
export const Text = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-family: 'Barlow';
  font-size: 1.42rem;
  line-height: 2.14rem;
  padding-bottom: 40px;
  width: 75%;
  opacity: 0.6;
`
