import styled from 'styled-components'
import { Link } from 'gatsby'

import arrowRightGreen from '../../../images/arrow-right-green.svg'
import clockIcon from '../../../images/clock.svg'

export const BlogStoriesSection = styled.section`
  background: linear-gradient(180deg, rgba(247, 247, 247, 0) 0%, #f7f7f7 100%);
  padding: 5rem 0;

  @media (max-width: 1024px) {
    padding: 2rem 0 5rem 0;
  }
`
export const BlogStoriesWrapper = styled.div`
  padding: 5rem 0 5rem 0;

  @media (max-width: 1024px) {
    padding: 1rem 0;
  }
`
export const ReadMoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    display: flex;
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-family: 'Barlow';
    font-size: 1.71rem;
    font-weight: bold;
    line-height: 2.3rem;
  }

  .right-arrow {
    margin-right: 1.42rem;
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    span {
      text-align: center;
      margin-bottom: 2rem;
    }
  }
`
export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1.14rem;
  line-height: 2rem;
  position: relative;

  &:hover {
    ::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 6px;
      background-color: ${({ theme }) => theme.colors.vividBlue};
      opacity: 0.3;
      bottom: 2px;
      left: 0;
    }
  }

  @media (max-width: 425px) {
    font-size: 1rem;
  }
`

export const Cards = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 340px;
  height: 460px;
  margin: 5rem auto;
  background: ${({ theme }) => theme.colors.pureWhite};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;

  @media only screen and (min-width: 1024px) {
    &:first-child {
      margin-right: auto;
      margin-left: 0;
    }

    &:last-child {
      margin-left: auto;
      margin-right: 0;
    }
  }

  a {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);

    & .card-image {
      transform: scale(1.05);
      backface-visibility: hidden;
    }
  }
  @media only screen and (min-width: 1024px) and (max-width: 1160px) {
    width: 320px;
  }

  @media only screen and (max-width: 1024px) {
    margin-top: 3rem;
    margin-bottom: 3rem;

    /* &:last-child {
      display: none;
    } */
  }

  @media only screen and (max-width: 425px) {
    width: 100%;
  }
`
export const CardImageWrapper = styled.figure`
  margin: 0;
  width: 340px;
  height: 210px;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .card-image {
    transition: transform 0.6s cubic-bezier(0.3, 0.61, 0.45, 0.94);
  }

  @media only screen and (min-width: 1024px) and (max-width: 1160px) {
    width: 320px;
    height: 190px;
  }

  @media only screen and (max-width: 425px) {
    width: 100%;
  }
`
export const CardContent = styled.div`
  padding: 0 1.42rem;
  margin: 2rem 0 auto 0;
  overflow: hidden;
`
export const CardTitle = styled.h3`
  color: ${({ theme }) => theme.colors.veryDarkBlue};
  font-family: 'Barlow';
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 2.14rem;
`
export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1.42rem;
`
export const Author = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGrey};

  figure {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 0.7rem;
    overflow: hidden;
    mask-image: radial-gradient(white, black);
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

export const Time = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGrey};

  span {
    display: flex;
    align-items: center;

    &::after {
      content: '';
      display: inline-flex;
      width: 16px;
      height: 16px;
      background-image: url(${clockIcon});
      background-repeat: no-repeat;
      margin-left: 0.4rem;
    }
  }
`
