import { useStaticQuery, graphql } from 'gatsby'

export const usePropertyImages = () => {
  const images = useStaticQuery(graphql`
    fragment PropertyImages on File {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 95) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    query {
      HotelImgOne: file(relativePath: { eq: "hotel-illustration-lights-off.jpg" }) {
        ...PropertyImages
      }
      HotelImgTwo: file(relativePath: { eq: "hotel-illustration-lights-on.jpg" }) {
        ...PropertyImages
      }
      HostelImgOne: file(relativePath: { eq: "hostel-illustration-lights-off.jpg" }) {
        ...PropertyImages
      }
      HostelImgTwo: file(relativePath: { eq: "hostel-illustration-lights-on.jpg" }) {
        ...PropertyImages
      }
      VacationImgOne: file(relativePath: { eq: "vacation-illustration-lights-off.jpg" }) {
        ...PropertyImages
      }
      VacationImgTwo: file(relativePath: { eq: "vacation-illustration-lights-on.jpg" }) {
        ...PropertyImages
      }
      PropertyManagersImgOne: file(relativePath: { eq: "property-managers-illustration-lights-off.jpg" }) {
        ...PropertyImages
      }
      PropertyManagersImgTwo: file(relativePath: { eq: "property-managers-illustration-lights-on.jpg" }) {
        ...PropertyImages
      }
    }
  `)

  return images
}
