import React, { useState } from 'react'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import Slider from 'react-slick'
import { LocaleConsumer } from '../../Layout'

import '~/../slick-carousel/slick/slick.css'
import '~/../slick-carousel/slick/slick-theme.css'

import {
  FeaturedCustomerSection,
  FeaturedCustomerWrapper,
  SlideStatus,
  SlideWrapper,
  SlideTextBox,
  SlideTitle,
  ImageWrapper
} from './styles'

import Container from '../../UI/Container'
import Button from '../../Button'

const FeaturedCustomer = ({ featuredCustomers, locale, location }) => {
  const lang = locale === 'en' ? `/${locale}/` : '/'

  const [state, setState] = useState({
    activeSlide: 0
  })
  const settings = {
    dots: true,
    arrows: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    onInit: () => {
      setState({ activeSlide: 1 })
    },
    afterChange: (currentSlide) => {
      setState({ activeSlide: currentSlide + 1 })
    },
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }
  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <FeaturedCustomerSection>
          <Container>
            <FeaturedCustomerWrapper>
              <SlideStatus>
                <span>{i18n.featuredCustomer}</span>
                <span>{`0${state.activeSlide}`}</span>
                <span>{`0${featuredCustomers.length}`}</span>
              </SlideStatus>

              <Slider {...settings}>
                {featuredCustomers.map(
                  ({
                    node: {
                      id,
                      title,
                      slug,
                      featured_media: {
                        localFile: {
                          childImageSharp: { fluid }
                        }
                      }
                    }
                  }) => {
                    return (
                      <SlideWrapper key={id}>
                        <SlideTextBox>
                          <SlideTitle>{title}</SlideTitle>
                          <Button as={Link} to={`${lang}blog/${slug}${location.search}`} type="arrow">
                            {i18n.readCustomerStory}
                          </Button>
                        </SlideTextBox>
                        <ImageWrapper>
                          <Img fluid={fluid} />
                        </ImageWrapper>
                      </SlideWrapper>
                    )
                  }
                )}
              </Slider>
            </FeaturedCustomerWrapper>
          </Container>
        </FeaturedCustomerSection>
      )}
    </LocaleConsumer>
  )
}

FeaturedCustomer.defaultProps = {
  location: {
    search: ''
  }
}

export default FeaturedCustomer
