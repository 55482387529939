import styled from 'styled-components'

import ctaImg from '../../images/cta.jpg'
import checkedIcon from '../../images/checked-green-icon.svg'

export const CtaSection = styled.section`
  padding: 1rem 0;

  @media (max-width: 425px) {
    padding: 2rem 0 4rem 0;
  }
`

export const CtaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5rem 5.71rem;

  background-image: radial-gradient(
      91.53% 537.19% at 34.46% 108.61%,
      rgba(13, 49, 81, 1) 0%,
      rgba(19, 71, 118, 0.8) 100%
    ),
    url(${ctaImg});
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.2);
  border-radius: 8px;

  @media (max-width: 1024px) {
    border-radius: 0;
    flex-direction: column;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`
export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 60%;
`

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 40%;
  align-items: center;

  @media (max-width: 1024px) {
    margin-top: 4rem;
  }
`

export const Title = styled.h3`
  font-family: 'Barlow';
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-size: 3.28rem;
  font-weight: 800;
  line-height: 3.57rem;
  margin-bottom: 0.5rem;

  @media (max-width: 1024px) {
    text-align: center;
    margin-bottom: 1.42rem;
  }
`

export const Subtitle = styled.p`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-size: 1.14rem;
  line-height: 2rem;
  width: 500px;
  margin-bottom: 4rem;

  @media (max-width: 1024px) {
    text-align: center;
    width: 85%;
    margin-right: auto;
    margin-left: auto;
  }
`
export const FormWrapper = styled.form`
  display: flex;

  input {
    color: ${({ theme }) => theme.colors.veryDarkBlue};
    font-weight: 400;
    font-size: 1.42rem;
    background: #ffffff;
    border: 1px solid #cfcfcf;
    border-radius: 8px;
    width: 300px;
    padding: 20px;
    margin-right: 2rem;

    &::placeholder {
      color: ${({ theme }) => theme.colors.darkGrey};
    }
    &:focus {
      outline: none;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;

    input {
      width: 100%;
      margin-right: 0;
      margin-bottom: 1.42rem;

      &::placeholder {
        text-align: center;
      }
    }
  }
`
export const SubmitButton = styled.button`
  display: block;
  background: ${({ theme }) => theme.colors.vividBlue};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.pureWhite};
  font-size: 1.28rem;
  font-weight: 500;
  line-height: 1.5rem;
  padding: 1.2rem 2rem;
  text-transform: uppercase;
  border: none;
  outline: none;
  transition: all 0.2s ease-in-out;
  will-change: transform, box-shadow, background-color;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.5);
    background-color: hsla(199, 76%, 43%, 1);
    transform: translateY(-3px);
  }

  &:active,
  &:focus {
    outline: none;
    transform: translateY(-1px);
    box-shadow: 0px 4px 10px rgba(0, 133, 255, 0.3);
  }

  @media only screen and (max-width: 768px) {
    padding: 19px 2rem;
  }
`

export const List = styled.ul`
  color: ${({ theme }) => theme.colors.lightestGrey};
  font-size: 1.28rem;
  margin-left: 7rem;

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      padding-bottom: 1.4rem;
    }
    &::before {
      content: '';
      display: inline-flex;
      width: 22px;
      height: 22px;
      background-image: url(${checkedIcon});
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 1rem;
    }
  }

  @media (max-width: 1024px) {
    margin-right: 0;
    margin-left: 0;
  }
`
