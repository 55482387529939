import React from 'react'

import { FeatureSection, FeatureWrapper, ColumnLeft, ColumnRight, FeatureBox, Title, Text } from './styles'
import Container from '../../UI/Container'

class Feature extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.handleFadeInAnimation()
  }

  handleFadeInAnimation = () => {
    const featureBoxes = document.querySelectorAll('.feature-box')
    const entryOptions = {
      threshold: 0.1
    }

    const featureObserver = new IntersectionObserver((entries, featureObserver) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return
        }

        if (entry.intersectionRatio > 0) {
          entry.target.style.animation = `fadeInFromBottom 1s ${entry.target.dataset.delay} forwards ease-in-out`
          featureObserver.unobserve(entry.target)
        }
      })
    }, entryOptions)

    featureBoxes.forEach((box) => {
      featureObserver.observe(box)
    })
  }

  render() {
    const { feature } = this.props
    return (
      <FeatureSection>
        <Container addMargin>
          <FeatureWrapper>
            <ColumnLeft>
              <FeatureBox className="feature-box" data-delay=".1s">
                <img src={feature.calendar.image.localFile.childImageSharp.fluid.src} alt="" />
                <Title dangerouslySetInnerHTML={{ __html: feature.calendar.title }} />
                <Text dangerouslySetInnerHTML={{ __html: feature.calendar.text }} />
              </FeatureBox>
              <FeatureBox className="feature-box" data-delay=".2s">
                <img src={feature.mobile_apps.image.localFile.childImageSharp.fluid.src} alt="" />
                <Title
                  dangerouslySetInnerHTML={{
                    __html: feature.mobile_apps.title
                  }}
                />
                <Text dangerouslySetInnerHTML={{ __html: feature.mobile_apps.text }} />
              </FeatureBox>
            </ColumnLeft>
            <ColumnRight>
              <FeatureBox className="feature-box" data-delay=".3s">
                <img src={feature.channel_manager.image.localFile.childImageSharp.fluid.src} alt="" />
                <Title
                  dangerouslySetInnerHTML={{
                    __html: feature.channel_manager.title
                  }}
                />
                <Text
                  dangerouslySetInnerHTML={{
                    __html: feature.channel_manager.text
                  }}
                />
              </FeatureBox>
              <FeatureBox className="feature-box" data-delay=".4s">
                <img src={feature.booking_engine.image.localFile.childImageSharp.fluid.src} alt="" />

                <Title
                  dangerouslySetInnerHTML={{
                    __html: feature.booking_engine.title
                  }}
                />
                <Text
                  dangerouslySetInnerHTML={{
                    __html: feature.booking_engine.text
                  }}
                />
              </FeatureBox>
            </ColumnRight>
          </FeatureWrapper>
        </Container>
      </FeatureSection>
    )
  }
}

export default Feature
