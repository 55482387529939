import React from 'react'
import PropTypes from 'prop-types'
import { LocaleConsumer } from '../../Layout'
import { usePropertyImages } from '../../../graphql/usePropertyImagesHook'
import { handleTrackedRentlioWebappClick } from 'helper/url'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

import {
  PropertySection,
  PropertyWrapper,
  PropertyBox,
  ImageContainer,
  Heading,
  Title,
  Text,
  StyledLink
} from './styles'

import Container from '../../UI/Container'

const Property = ({ property, location }) => {
  const {
    HotelImgOne,
    HotelImgTwo,
    HostelImgOne,
    HostelImgTwo,
    VacationImgOne,
    VacationImgTwo,
    PropertyManagersImgOne,
    PropertyManagersImgTwo
  } = usePropertyImages()
  const mixpanel = useMixpanel()

  return (
    <LocaleConsumer>
      {({ i18n }) => (
        <PropertySection>
          <Container width={1200}>
            <Heading>{property.property_heading}</Heading>
            <PropertyWrapper>
              <PropertyBox>
                <StyledLink to={`/${property.hotel.url}${location.search}`} onClick={(e) => handleTrackedRentlioWebappClick(e, '', '', mixpanel, 'user_segment_clicked', 'homepage', false, true, false, 'hotel')}>
                  <ImageContainer>
                    <img src={HotelImgOne.childImageSharp.fixed.src} alt="" />
                    <img src={HotelImgTwo.childImageSharp.fixed.src} alt="" />
                  </ImageContainer>
                  <Title>{property.hotel.title}</Title>
                  <Text>{property.hotel.text}</Text>
                  <span>{i18n.explore}</span>
                </StyledLink>
              </PropertyBox>
              <PropertyBox>
                <StyledLink to={`/${property.hostel.url}${location.search}`} onClick={(e) => handleTrackedRentlioWebappClick(e, '', '', mixpanel, 'user_segment_clicked', 'homepage', false, true, false, 'hostel')}>
                  <ImageContainer>
                    <img src={HostelImgOne.childImageSharp.fixed.src} alt="" />
                    <img src={HostelImgTwo.childImageSharp.fixed.src} alt="" />
                  </ImageContainer>
                  <Title>{property.hostel.title}</Title>
                  <Text>{property.hostel.text}</Text>
                  <span>{i18n.explore}</span>
                </StyledLink>
              </PropertyBox>
              <PropertyBox>
                <StyledLink to={`/${property.vacation_rentals.url}${location.search}`} onClick={(e) => handleTrackedRentlioWebappClick(e, '', '', mixpanel, 'user_segment_clicked', 'homepage', false, true, false, 'vacation_rentals')}> 
                  <ImageContainer>
                    <img src={VacationImgOne.childImageSharp.fixed.src} alt="" />
                    <img src={VacationImgTwo.childImageSharp.fixed.src} alt="" />
                  </ImageContainer>
                  <Title>{property.vacation_rentals.title}</Title>
                  <Text>{property.vacation_rentals.text}</Text>
                  <span>{i18n.explore}</span>
                </StyledLink>
              </PropertyBox>
              <PropertyBox>
                <StyledLink to={`/${property.property_managers.url}${location.search}`} onClick={(e) => handleTrackedRentlioWebappClick(e, '', '', mixpanel, 'user_segment_clicked', 'homepage', false, true, false, 'property_managers')}>
                  <ImageContainer>
                    <img src={PropertyManagersImgOne.childImageSharp.fixed.src} alt="" />
                    <img src={PropertyManagersImgTwo.childImageSharp.fixed.src} alt="" />
                  </ImageContainer>
                  <Title>{property.property_managers.title}</Title>
                  <Text>{property.property_managers.text}</Text>
                  <span>{i18n.explore}</span>
                </StyledLink>
              </PropertyBox>
            </PropertyWrapper>
          </Container>
        </PropertySection>
      )}
    </LocaleConsumer>
  )
}

Property.propTypes = {
  property: PropTypes.object,
  location: PropTypes.object
}

Property.defaultProps = {
  location: {
    search: ''
  }
}

export default Property
